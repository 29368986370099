<script>
import { KModal } from '@/components/_base'
import FeedbackForm from './FeedbackForm.vue'
import KMountedMixin from '@/mixins/KMountedMixin.vue'

export default {
    mixins: [KMountedMixin],
    components: { KModal, FeedbackForm },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        show_back() {
            return (
                this.isOpen &&
                this.$refs['feedbackForm'] &&
                !this.$refs['feedbackForm']?.success &&
                !!this.$refs['feedbackForm']?.formData.type
            )
        },
    },
    methods: {
        goBack() {
            this.$refs['feedbackForm'].formData.type = undefined
        },
    },
}
</script>

<template>
    <KModal v-model="isOpen" md-right>
        <template #trigger="params">
            <slot name="trigger" v-bind="params" />
        </template>
        <template #default>
            <div class="mt-10">
                <KButton
                    v-if="show_back"
                    @click="goBack"
                    class="absolute -left-2 top-0 md:-left-1 md:top-2"
                    iconLeft="ArrowSmallLeftIcon"
                    mode="text"
                >
                    {{ $t('go back') }}
                </KButton>
                <FeedbackForm ref="feedbackForm" />
            </div>
        </template>
    </KModal>
</template>
