<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        disabled: Boolean,
        fieldAttrs: Object,
        label: String,
        placeholder: String,
        name: { type: String, required: true },
        rules: { type: Function, default: () => [] },
        isErrored: { type: Boolean, default: false },
    },
    computed: {
        is_required() {
            if (this.rules?.validatorsList) {
                return this.rules?.validatorsList?.includes('required')
            }

            return this.rules?.validatorName?.includes('required')
        },
    },
})
</script>
