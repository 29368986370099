<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'KSpinner',
    props: {
        size: {
            type: Number,
            default: 32,
        },
        color: {
            type: String,
            default: 'var(--color-brand)',
        },
    },
})
</script>

<template>
    <div class="lds-ring" :style="{ '--size': `${size}px`, '--color': color }">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<style scoped>
.lds-ring {
    display: inline-block;
    position: relative;
    width: var(--size);
    height: var(--size);
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(var(--size) * 0.8);
    height: calc(var(--size) * 0.8);
    margin: calc(var(--size) * 0.1);
    border: calc(var(--size) * 0.1) solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color) transparent transparent transparent;
    transition: border-color 0.2s;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
