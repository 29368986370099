<script>
import { mapActions, mapGetters } from 'vuex'
import { KDropDown } from '@/components/_base'

import ArrowPathIcon from '@/assets/images/arrow_path.svg'

export default {
    name: 'WorkspaceSwitcher',
    components: { KDropDown, ArrowPathIcon },
    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
    },
}
</script>

<template>
    <KDropDown :position="{ x: 'right', y: 'bottom' }">
        <template #trigger="{ click }">
            <div @click="click" class="px-4 py-1 cursor-pointer">
                <div class="relative">
                    <ArrowPathIcon
                        class="absolute top-0 left-[-1px] max-w-none stroke-brand"
                        style="stroke-width: 1.5px; transform: scale(1.3)"
                    />
                    <div
                        class="w-6 h-6 flex items-center justify-center bg-brand rounded-full mr-2"
                    >
                        <img src="/images/kinchaku-logo-mobile.png" class="w-3" />
                    </div>
                </div>
            </div>
        </template>
        <template>
            <div class="flex flex-col w-40">
                <div class="px-4 py-2 border-b text-secondary--text font-medium">
                    {{ $t('Switch wallet') }}
                </div>
                <KButton
                    class="whitespace-nowrap px-4"
                    :class="{
                        'bg-indigo-light--background !text-brand': $root.lang === 'en',
                    }"
                    mode="text"
                >
                    <div class="flex items-center">
                        <div
                            class="w-6 h-6 flex items-center justify-center bg-brand rounded-full mr-2"
                        >
                            <img src="/images/kinchaku-logo-mobile.png" class="w-3" />
                        </div>
                        <div class="truncate w-28 text-left">Kinchaku</div>
                    </div>
                </KButton>
                <KButton
                    class="whitespace-nowrap px-4 truncate"
                    :class="{
                        'bg-indigo-light--background !text-brand': $root.lang === 'ja',
                    }"
                    mode="text"
                >
                    <div class="flex items-center">
                        <div
                            class="w-6 h-6 flex items-center justify-center bg-brand rounded-full mr-2"
                        >
                            <img src="/images/kinchaku-logo-mobile.png" class="w-3" />
                        </div>
                        <div class="truncate w-28 text-left">Brand Name</div>
                    </div>
                </KButton>
            </div>
        </template>
    </KDropDown>
</template>
