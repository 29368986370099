<script>
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

import FieldMixin from './mixins/FieldMixin.vue'
import FieldLayout from './layouts/FieldLayout.vue'

export default defineComponent({
    name: 'KRadioField',
    mixins: [FieldMixin],
    components: { FieldLayout },
    props: {
        items: { type: Array, required: true },
        itemText: { type: String, default: 'text' },
        itemValue: { type: String, default: 'value' },
        modelValue: { type: [String, Number, Boolean], default: undefined },
        block: Boolean,
    },
    setup(props) {
        const { errorMessage, handleChange, handleBlur, value } = useField(
            props.name,
            props.rules,
            {
                initialValue: props.modelValue,
            }
        )

        return { errorMessage, handleChange, handleBlur, value }
    },
    watch: {
        value() {
            this.$emit('update:modelValue', this.value)
        },
    },
})
</script>

<template>
    <FieldLayout v-bind="$attrs" :name="name" :label="label" :is-required="is_required">
        <div>
            <label
                v-for="(item, index) in items"
                :key="item[itemValue]"
                :class="{
                    'ml-6': index > 0 && !block,
                    'mt-4': index > 0 && block,
                    'inline-flex': !block,
                    'block border px-4 py-3 rounded-md': block,
                    'border-brand': block && item[itemValue] === modelValue,
                    'border-primary--border ': block && item[itemValue] !== modelValue,
                }"
                class="items-center transition accent-brand"
            >
                <input
                    @input="handleChange"
                    @blur="handleBlur"
                    :name="name"
                    :value="item[itemValue]"
                    :disabled="disabled"
                    :checked="item[itemValue] === modelValue"
                    type="radio"
                />
                <span class="ml-2">{{ item[itemText] }}</span>
            </label>
        </div>
    </FieldLayout>
</template>
