<script>
import { mapActions, mapGetters } from 'vuex'

import { KModal, KIcon } from '@/components/_base'
import KMountedMixin from '@/mixins/KMountedMixin.vue'

export default {
    mixins: [KMountedMixin],
    components: { KModal, KIcon },
    props: {
        modelValue: Boolean,
    },
    data() {
        return {
            isOpen: false,
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            brand_name: 'brand_name',
        }),
    },
    watch: {
        modelValue() {
            this.isOpen = this.modelValue
        },
    },
    methods: {
        ...mapActions({
            hideAccessToWorkspaceModal: 'hideAccessToWorkspaceModal',
            connectUserToWorkspace: 'account/connectUserToWorkspace',
            continueIssuingPass: 'cart/continueIssuingPass',
        }),
        async setupUserInWorkspace() {
            try {
                this.loading = true

                await this.connectUserToWorkspace()
                await this.continueIssuingPass()

                this.hideAccessToWorkspaceModal()
            } catch (error) {
                this.$root.handleError(error)
            } finally {
                this.loading = false
            }
        },
        rejected() {
            this.hideAccessToWorkspaceModal()
        },
    },
}
</script>

<template>
    <KModal
        v-model="isOpen"
        @close="hideAccessToWorkspaceModal"
        :show-close="!loading"
        class="max-w-md flex justify-center flex-col md:block"
        xs-full
    >
        <template #default>
            <div class="mb-10 md:mt-10">
                <div
                    class="w-12 h-12 flex items-center justify-center rounded-full mx-auto bg-amber-500"
                >
                    <KIcon icon="ExclamationTriangleIcon" class="text-white" />
                </div>
                <p class="text-center mt-3 mb-2">
                    {{ $t('Need access') }}
                </p>
                <p class="text-center text-sm text-secondary--text">
                    {{
                        $t(
                            '{brand_name} needs access to your personal data. Are you sure you want to allow access?',
                            { brand_name }
                        )
                    }}
                </p>
            </div>
            <div class="grid grid-cols-2 gap-x-4 w-full">
                <KButton @click="rejected" :disabled="loading" mode="secondary">
                    {{ $t('No') }}
                </KButton>
                <KButton @click="setupUserInWorkspace" :loading="loading">
                    {{ $t('Yes') }}
                </KButton>
            </div>
        </template>
    </KModal>
</template>
