<script>
import { defineComponent } from 'vue'
import { Field } from 'vee-validate'

import FieldMixin from './mixins/FieldMixin.vue'
import FieldLayout from './layouts/FieldLayout.vue'
import KButton from '../KButton.vue'

export default defineComponent({
    mixins: [FieldMixin],
    name: 'KCounterField',
    components: { FieldLayout, Field, KButton },
    props: {
        modelValue: { type: Number, default: 0 },
        min: { type: Number, default: undefined },
        max: { type: Number, default: undefined },
        disabledType: { type: Boolean, default: true },
        disabled: Boolean,
    },
    data() {
        return { value: 0 }
    },
    computed: {
        disabled_all() {
            return this.disabled || (this.min == 0 && this.max == 0)
        },
        disabled_minus() {
            return this.disabled_all || (this.min !== undefined && this.value <= this.min)
        },
        disabled_plus() {
            return this.disabled_all || (this.max !== undefined && this.value >= this.max)
        },
        class_list() {
            return {
                'bg-white': !this.disabled_all,
                'bg-primary--background text-secondary--text shadow-none': this.disabled_all,
            }
        },
    },
    mounted() {
        this.value = this.modelValue || 0
    },
    watch: {
        modelValue() {
            this.value = this.modelValue
        },
    },
    methods: {
        minus() {
            if (!this.disabled_minus) this.$emit('update:modelValue', this.value - 1)
        },
        plus() {
            if (!this.disabled_plus) this.$emit('update:modelValue', this.value + 1)
        },
    },
})
</script>

<template>
    <Field v-slot="{ errorMessage }" :name="name" :rules="rules">
        <FieldLayout v-bind="$attrs" :name="name" :label="label" :is-required="is_required">
            <div class="flex items-center rounded-md border border-primary--border bg-white">
                <KButton
                    @click="minus"
                    :disabled="disabled_minus"
                    mode="icon"
                    icon="MinusSmallIcon"
                    class="p-2"
                />
                <input
                    v-bind="{ ...fieldAttrs }"
                    v-model="value"
                    :class="{ ...class_list, 'border-red': errorMessage }"
                    :disabled="disabled || disabledType"
                    :placeholder="placeholder"
                    :min="min"
                    :max="max"
                    class="counter-input min-h-full px-3 border border-t-0 border-b-0 border-primary--border transition w-[52px] text-center"
                    type="number"
                />
                <KButton
                    @click="plus"
                    :disabled="disabled_plus"
                    mode="icon"
                    icon="PlusSmallIcon"
                    class="p-2"
                />
            </div>
        </FieldLayout>
    </Field>
</template>

<style scoped>
.counter-input::-webkit-outer-spin-button,
.counter-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.counter-input[type='number'] {
    -moz-appearance: textfield;
}
</style>
