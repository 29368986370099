<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'KAvatar',
    props: {
        src: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: 'M',
        },
    },
    computed: {
        class_list() {
            return {
                'w-10 h-10': this.size === 'M',
            }
        },
    },
})
</script>

<template>
    <img :src="src" class="rounded-full object-cover" :class="class_list" />
</template>
