<script>
import { defineComponent } from 'vue'

import { EMAIL_REGEX, URL_REGEX } from '../utils/validation'

export default defineComponent({
    name: 'KFormValidatorsMixin.vue',
    data() {
        return {
            fieldValidators: {
                required: ({ isRequired = true } = { isRequired: true }) => {
                    const required = v => !!v || this.$t('Required Field')
                    required.validatorName = 'required'
                    return isRequired ? required : () => true
                },
                requiredBoolean: ({ isRequired = true } = { isRequired: true }) => {
                    const requiredBoolean = v =>
                        v === true || v === false || this.$t('Required Field')
                    requiredBoolean.validatorName = 'requiredBoolean'
                    return isRequired ? requiredBoolean : () => true
                },
                requiredArray: ({ isRequired = true } = { isRequired: true }) => {
                    const requiredArray = v => (!!v && !!v.length) || this.$t('Required Field')
                    requiredArray.validatorName = 'requiredArray'
                    return isRequired ? requiredArray : () => true
                },
                email: () => v => !v || EMAIL_REGEX.test(v) || this.$t('Must be a valid email'),
                phone: () => v =>
                    !v ||
                    /^0?[\d()\s-–]{8,17}$/.test(v) ||
                    this.$t('Please input correct Japan phone number'),
                url: v => !v || URL_REGEX.test(v) || this.$t('Must be a valid URL'),
                minCharacters:
                    ({ minCount }) =>
                    v =>
                        !v ||
                        v.length >= minCount ||
                        this.$t('The value must be greater than or equal {count} characters.', {
                            count: minCount,
                        }),
                maxCharacters:
                    ({ maxCount }) =>
                    v =>
                        !v ||
                        v.length <= maxCount ||
                        this.$t('The value must be less than or equal {count} characters.', {
                            count: maxCount,
                        }),
                katakana: () => v => {
                    const pattern = /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/
                    return !v || pattern.test(v) || this.$t('Please input katakana only')
                },
                squareImageMin:
                    ({ min }) =>
                    v =>
                        (v && v.width === v.height && v.width >= min) ||
                        this.$t(`Must be square and at least ${min}x${min}px`),
                imageSizeMb:
                    ({ maxMb = 10 } = { maxMb: 10 }) =>
                    v => {
                        const files = Array.isArray(v) ? v : [v]
                        return (
                            !v ||
                            files?.every(f => f.size <= maxMb * Math.pow(2, 20)) ||
                            this.$t('File size limit {count}Mb.', { count: maxMb })
                        )
                    },
                imageFile: () => v => {
                    const files = Array.isArray(v) ? v : [v]
                    return (
                        !v ||
                        files?.every(f =>
                            ['image/jpeg', 'image/png', 'image/heic'].includes(f.type)
                        ) ||
                        this.$t('Invalid image')
                    )
                },
            },
        }
    },
    methods: {
        combineFieldValidators(validatorsArray) {
            const validator = v => {
                for (const validator of validatorsArray) {
                    const result = validator(v)

                    if (result !== true) return result
                }

                return true
            }

            validator.validatorsList = validatorsArray.map(v => v.validatorName)

            return validator
        },
    },
})
</script>
