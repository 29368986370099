import { replace } from './format'
import { set as setTranslations, fetchTranslation } from './translations'

export default {
    init(translations) {
        setTranslations(translations)
    },

    translate(lang, key, replacements = {}) {
        if (!key) return ''
        let choice = null

        if (typeof replacements === 'number') {
            choice = replacements
        } else if (replacements && replacements.count !== undefined) {
            choice = replacements.count
        }

        let translation = fetchTranslation(lang, key, choice)

        return replace(translation, replacements)
    },
}
