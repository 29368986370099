import { twMerge } from 'tailwind-merge'

export const convertToClassString = data => {
    if (!data) return ''

    let classListString = ''

    if (typeof data === 'string') return data
    if (Array.isArray(data)) {
        for (const classes of data) {
            classListString += ` ${convertToClassString(classes)}`
        }
    }
    if (typeof data === 'object') {
        for (const className in data) {
            if (data[className] === 'object')
                classListString += ` ${convertToClassString(data[className])}`
            else if (data[className]) classListString += ` ${className}`
        }
    }

    return twMerge(classListString)
}

export default {
    convertToClassString,
}
