<script>
import { defineComponent } from 'vue'
import { Collapse } from 'vue-collapsed'

import KButton from './KButton.vue'
import KDivider from './KDivider.vue'
import KIcon from './KIcon.vue'
import { convertToClassString } from '@/utils/class'

export default defineComponent({
    name: 'KCard',
    components: { KButton, KIcon, KDivider, Collapse },
    props: {
        mode: {
            type: String,
            default: 'grey',
        },
        label: {
            type: String,
            default: undefined,
        },
        labelIcon: {
            type: String,
            default: undefined,
        },
        labelBorder: {
            type: Boolean,
            default: true,
        },
        labelClass: {
            type: [String, Object],
        },
        href: {
            type: String,
            default: undefined,
        },
        to: {
            type: [String, Object],
            default: undefined,
        },
        fill: {
            type: Boolean,
            default: false,
        },
        defaultIsExpanded: {
            type: Boolean,
            default: false,
        },
        target: String,
    },
    data() {
        return {
            isExpanded: false,
            showAdditional: false,
        }
    },
    beforeMount() {
        this.isExpanded = !!this.defaultIsExpanded
    },
    watch: {
        isExpanded() {
            if (this.isExpanded) {
                this.showAdditional = true
            } else {
                setTimeout(() => {
                    this.showAdditional = false
                }, 300)
            }
        },
    },
    computed: {
        as_button() {
            return this.$slots['additional'] || this.$attrs['onClick'] || this.href || this.to
        },
        container_class_string() {
            return convertToClassString([this.container_class, this.$attrs['class']])
        },
        container_class() {
            return {
                'rounded-md overflow-hidden text-left': true,

                'border border-primary--border bg-white': this.mode === 'white',
                'bg-indigo-light--background': this.mode === 'grey',
            }
        },
    },
})
</script>

<template>
    <div :class="container_class_string">
        <div
            v-if="$slots['header']"
            class="border-b border-primary--border bg-indigo-light--background p-4"
        >
            <slot name="header" />
        </div>
        <div :class="{ 'p-4': !fill }">
            <component
                v-if="label"
                v-bind="to ? { to, target } : href ? { href, target } : {}"
                v-on="$slots['additional'] ? { click: () => (isExpanded = !isExpanded) } : {}"
                :is="to ? 'router-link' : href ? 'a' : 'div'"
                :class="[
                    {
                        'cursor-pointer': as_button,
                        'hover:bg-indigo-light--hover': as_button && mode === 'grey',
                        'border-primary--border': isExpanded,
                        'border-transparent': !isExpanded,
                        'border-b': labelBorder,
                    },
                    labelClass,
                ]"
                class="flex justify-between items-center transition-colors -m-4 px-4 py-3 whitespace-pre-line"
            >
                <div class="flex items-center flex-1">
                    <KIcon v-if="labelIcon" :icon="labelIcon" class="mr-2" />
                    <div class="first-letter:capitalize">{{ label }}</div>
                </div>
                <KIcon
                    v-if="$attrs['onClick'] || href || to"
                    icon="ChevronRightIcon"
                    class="text-primary--graphic"
                />
                <KIcon
                    v-else-if="$slots['additional']"
                    :icon="isExpanded ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    class="text-primary--graphic"
                />
            </component>
            <div v-if="$slots.default">
                <slot />
            </div>
            <div v-if="$slots['additional']">
                <Collapse :when="isExpanded" class="v-collapse">
                    <div
                        :class="{ 'mb-4': $slots.default && !label, 'mt-8': label && labelBorder }"
                    >
                        <slot name="additional" />
                        <KDivider v-if="$slots.default && !label" class="mt-4" />
                    </div>
                </Collapse>
                <KButton
                    v-if="$slots.default && !label"
                    @click="isExpanded = !isExpanded"
                    :icon-right="isExpanded ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    class="w-full"
                    mode="secondary"
                >
                    {{ isExpanded ? 'Hide details' : 'See details' }}
                </KButton>
            </div>
        </div>
    </div>
</template>

<style scoped>
.v-collapse {
    transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
</style>
