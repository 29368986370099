<script>
import { KModal } from '@/components/_base'

export default {
    components: { KModal },
    data() {
        return {
            showConsentDialog: false,
            formData: {
                withEssential: true,
                withPerformance: false,
            },
        }
    },
    mounted() {
        this.checkConsentTimeout()
    },
    methods: {
        checkConsent() {
            try {
                if (localStorage.getItem('cookieConsent') === null) {
                    this.showConsentDialog = true
                }
            } catch (error) {
                // eslint-disable-next-line
                console.warn(error)
            }
        },
        checkConsentTimeout(counter = 0) {
            setTimeout(() => {
                if (counter > 100) return
                if (this.$matomo) {
                    this.checkConsent()
                } else {
                    this.checkConsentTimeout(++counter)
                }
            }, 100)
        },
        acceptAll() {
            this.formData.withPerformance = true
            this.showConsentDialog = false
            localStorage.setItem('cookieConsent', true)
            this.$matomo.rememberConsentGiven()
        },
        confirm() {
            this.showConsentDialog = false

            if (this.formData.withPerformance) {
                localStorage.setItem('cookieConsent', true)
                this.$matomo.rememberConsentGiven()
            } else {
                localStorage.setItem('cookieConsent', false)
                this.$matomo.forgetConsentGiven()
            }
        },
    },
}
</script>

<template>
    <KModal
        v-model="showConsentDialog"
        :showClose="false"
        class="w-full max-w-md bg-primary--background"
    >
        <div class="mt-10">
            <div
                class="w-12 h-12 flex items-center justify-center rounded-full mx-auto bg-brand mb-4"
            >
                <KIcon icon="InformationCircleIcon" class="text-white" />
            </div>
            <h3 class="text-center font-medium">{{ $t('This website uses cookies') }}</h3>
            <div class="text-secondary--text text-sm text-center mt-2">
                <template v-if="$root.is_japanese">
                    当社は、以下の目的で Cookie および同様のツール (以下総称して「Cookie」)
                    を使用いたします。
                </template>
                <template v-else>
                    We use cookies and similar tools (collectively, "cookies") for the following
                    purposes.
                </template>
            </div>
            <div class="my-6">
                <div class="flex p-4 bg-secondary--graphic rounded-md">
                    <KCheckField
                        v-model="formData.withEssential"
                        class="mt-0.5 mr-2"
                        name="withEssential"
                        disabled
                    />
                    <div>
                        <div class="font-medium text-secondary--text">Essential Cookie</div>
                        <div class="text-sm text-secondary--text">
                            <template v-if="$root.is_japanese">
                                当社のサイトおよびサービスを提供するために必要であり、無効にすることはできません。
                            </template>
                            <template v-else>
                                Essential cookies are necessary to provide our site and services and
                                cannot be deactivated.
                            </template>
                        </div>
                    </div>
                </div>
                <div
                    @click="() => (formData.withPerformance = !formData.withPerformance)"
                    class="flex p-4 cursor-pointer"
                >
                    <KCheckField
                        v-model="formData.withPerformance"
                        @click="() => (formData.withPerformance = !formData.withPerformance)"
                        class="mt-0.5 mr-2"
                        name="withPerformance"
                    />
                    <div>
                        <div class="font-medium">Performance Cookie</div>
                        <div class="text-sm text-secondary--text">
                            <template v-if="$root.is_japanese">
                                お客様によるサイトの操作方法に関する匿名の統計を提供するため、
                                サイトの使い勝手とパフォーマンスを向上させることができます。
                            </template>
                            <template v-else>
                                Performance cookies provide anonymous statistics about how customers
                                navigate our site so we can improve site experience and performance.
                            </template>
                            <template v-if="$root.is_japanese">
                                承認された第三者は、当社に代わって分析を行う場合がありますが、データを独自の目的で使用することはできません。
                            </template>
                            <template v-else>
                                Approved third parties may perform analytics on our behalf, but they
                                cannot use the data for their own purposes.
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid md:grid-cols-2 gap-4 mt-6">
                <KButton @click="confirm" mode="secondary" class="capitalize">
                    {{ $t('confirm') }}
                </KButton>
                <KButton @click="acceptAll" mode="primary" class="capitalize">
                    {{ $t('accept all') }}
                </KButton>
            </div>
        </div>
    </KModal>
</template>
