<script>
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            isMounted: false,
        }
    },
    mounted() {
        this.isMounted = true
    },
})
</script>
