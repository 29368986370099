<script>
import { mapActions, mapGetters } from 'vuex'

import AccountShortCard from '@/components/account/AccountShortCard.vue'
import WorkspaceSwitcher from '@/components/WorkspaceSwitcher.vue'

export default {
    name: 'MainMenu',
    components: { AccountShortCard, WorkspaceSwitcher },
    computed: {
        ...mapGetters({
            is_whitelabel_mode: 'is_whitelabel_mode',

            slug: 'project/slug',
        }),
    },
    methods: {
        ...mapActions({
            showAccountLoader: 'showAccountLoader',
            hideAccountLoader: 'hideAccountLoader',
            signOut: 'account/signOut',
        }),
        async onSignOut() {
            try {
                this.showAccountLoader()

                await this.signOut()

                this.$emit('signOut')

                return this.$router.push(
                    this.slug
                        ? { name: 'projectIndexPage', params: { slug: this.slug } }
                        : this.is_whitelabel_mode
                        ? { name: 'signInPage' }
                        : { name: 'indexPage' }
                )
            } catch (error) {
                this.$root.handleError(error)
            } finally {
                this.hideAccountLoader()
            }
        },
    },
}
</script>

<template>
    <div
        id="main-menu"
        class="flex flex-col py-6 bg-primary--background md:bg-white md:rounded-md md:max-w-sm z-10"
    >
        <div class="flex-1">
            <template v-if="$root.authenticated">
                <AccountShortCard class="px-4" />
                <div class="m-4">
                    <KDivider />
                </div>
                <div>
                    <div class="flex items-center justify-between">
                        <KButton
                            :to="{ name: 'walletPage' }"
                            iconLeft="WalletIcon"
                            mode="text"
                            size="M"
                        >
                            {{ $t('my wallet') }}
                        </KButton>
                        <WorkspaceSwitcher v-if="$root.dev" />
                    </div>
                    <KButton
                        :to="{ name: 'accountInformationPage' }"
                        iconLeft="ClipboardDocumentListIcon"
                        mode="text"
                        size="M"
                    >
                        {{ $t('customer information') }}
                    </KButton>

                    <KButton
                        :to="{ name: 'accountPasswordPage' }"
                        iconLeft="LockOpenIcon"
                        mode="text"
                        size="M"
                    >
                        {{ $t('update password') }}
                    </KButton>

                    <KButton
                        :to="{ name: 'accountPaymentHistoryPage' }"
                        iconLeft="BanknotesIcon"
                        mode="text"
                        size="M"
                    >
                        {{ $t('payment history') }}
                    </KButton>

                    <KButton
                        :to="{ name: 'accountPaymentMethodPage' }"
                        iconLeft="CreditCardIcon"
                        mode="text"
                        size="M"
                    >
                        {{ $t('payment method') }}
                    </KButton>
                </div>
                <div class="m-4"><KDivider /></div>
            </template>
            <div>
                <KButton
                    :to="{ name: 'privacyPage' }"
                    mode="text"
                    class="text-secondary--text font-medium text-sm"
                    size="M"
                    target="_blank"
                >
                    KINCHAKU {{ $c($t('privacy policy')) }}
                </KButton>
                <KButton
                    :to="{ name: 'termsPage' }"
                    mode="text"
                    class="text-secondary--text font-medium text-sm"
                    size="M"
                    target="_blank"
                >
                    KINCHAKU {{ $c($t('terms and conditions')) }}
                </KButton>
                <div class="m-4"><KDivider /></div>
            </div>
            <KButton
                v-if="$root.authenticated"
                @click="onSignOut"
                iconLeft="ArrowRightOnRectangleIcon"
                class="text-secondary--text"
                mode="text"
                size="M"
            >
                {{ $t('sign out') }}
            </KButton>
            <KButton
                v-else
                :to="
                    slug ? { name: 'projectSignInPage', params: { slug } } : { name: 'signInPage' }
                "
                iconLeft="ArrowLeftOnRectangleIcon"
                class="text-brand"
                mode="text"
                size="M"
            >
                {{ $t('sign in') }}
            </KButton>
        </div>
        <div class="text-secondary--text text-xs text-center mx-4 md:hidden">
            {{ $t('Developed by KINCHAKU INC.') }}
        </div>
    </div>
</template>
