<script>
import { mapActions, mapState } from 'vuex'
import { KToast } from '@/components/_base'

export default {
    components: { KToast },
    computed: {
        ...mapState({ toasts: 'toasts' }),
    },
    methods: {
        ...mapActions({ removeToast: 'removeToast' }),
    },
}
</script>

<template>
    <TransitionGroup
        name="list"
        tag="div"
        class="flex flex-col items-center justify-center fixed right-0 mx-4 my-1 top-0 z-50"
    >
        <KToast
            v-for="(toast, index) of toasts"
            :toast="toast"
            :key="index"
            @close="() => removeToast(toast)"
            class="mt-3"
        />
    </TransitionGroup>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
