function buildFormData(formData, data, parentKey) {
    if (Array.isArray(data) && Object.keys(data).length === 0) formData.append(parentKey, [])
    if (data && typeof data === 'object' && ![Date, File, Blob].some(t => data instanceof t)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
    }
}

export function jsonToFormData(data) {
    const formData = new FormData()

    buildFormData(formData, data)

    return formData
}
