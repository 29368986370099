import {
    KCard,
    KAvatar,
    KIcon,
    KTextField,
    KSelectField,
    KCheckField,
    KRadioField,
    KDivider,
    KCounterField,
    KButton,
} from '@/components/_base'

export default {
    install(app) {
        app.component('KCard', KCard)
        app.component('KAvatar', KAvatar)
        app.component('KIcon', KIcon)
        app.component('KDivider', KDivider)
        app.component('KCounterField', KCounterField)
        app.component('KButton', KButton)
        app.component('KTextField', KTextField)
        app.component('KSelectField', KSelectField)
        app.component('KCheckField', KCheckField)
        app.component('KRadioField', KRadioField)
    },
}
