<script>
import { defineComponent } from 'vue'

import ArrowLeftOnRectangleIcon from '@heroicons/vue/24/outline/ArrowLeftOnRectangleIcon'
import ArrowRightIcon from '@heroicons/vue/24/outline/ArrowRightIcon'
import ArrowRightOnRectangleIcon from '@heroicons/vue/24/outline/ArrowRightOnRectangleIcon'
import ArrowSmallLeftIcon from '@heroicons/vue/24/outline/ArrowSmallLeftIcon'
import BanknotesIcon from '@heroicons/vue/24/outline/BanknotesIcon'
import Bars3Icon from '@heroicons/vue/24/outline/Bars3Icon'
import BugAntIcon from '@heroicons/vue/24/outline/BugAntIcon'
import BuildingStorefrontIcon from '@heroicons/vue/24/outline/BuildingStorefrontIcon'
import CalendarDaysIcon from '@heroicons/vue/24/outline/CalendarDaysIcon'
import CameraIcon from '@heroicons/vue/24/outline/CameraIcon'
import ChatBubbleOvalLeftEllipsisIcon from '@heroicons/vue/24/outline/ChatBubbleOvalLeftEllipsisIcon'
import CheckCircleIcon from '@heroicons/vue/24/outline/CheckCircleIcon'
import CheckIcon from '@heroicons/vue/24/outline/CheckIcon'
import ChevronDownIcon from '@heroicons/vue/24/outline/ChevronDownIcon'
import ChevronLeftIcon from '@heroicons/vue/24/outline/ChevronLeftIcon'
import ChevronRightIcon from '@heroicons/vue/24/outline/ChevronRightIcon'
import ChevronUpIcon from '@heroicons/vue/24/outline/ChevronUpIcon'
import ClipboardDocumentListIcon from '@heroicons/vue/24/outline/ClipboardDocumentListIcon'
import ClockIcon from '@heroicons/vue/24/outline/ClockIcon'
import Cog6ToothIcon from '@heroicons/vue/24/outline/Cog6ToothIcon'
import CreditCardIcon from '@heroicons/vue/24/outline/CreditCardIcon'
import CurrencyDollarIcon from '@heroicons/vue/24/outline/CurrencyDollarIcon'
import CurrencyYenIcon from '@heroicons/vue/24/outline/CurrencyYenIcon'
import DocumentTextIcon from '@heroicons/vue/24/outline/DocumentTextIcon'
import EllipsisHorizontalCircleIcon from '@heroicons/vue/24/outline/EllipsisHorizontalCircleIcon'
import EnvelopeIcon from '@heroicons/vue/24/outline/EnvelopeIcon'
import EnvelopeOpenIcon from '@heroicons/vue/24/outline/EnvelopeOpenIcon'
import ExclamationCircleIcon from '@heroicons/vue/24/outline/ExclamationCircleIcon'
import ExclamationTriangleIcon from '@heroicons/vue/24/outline/ExclamationTriangleIcon'
import FingerPrintIcon from '@heroicons/vue/24/outline/FingerPrintIcon'
import GiftIcon from '@heroicons/vue/24/outline/GiftIcon'
import GlobeAltIcon from '@heroicons/vue/24/outline/GlobeAltIcon'
import HomeIcon from '@heroicons/vue/24/outline/HomeIcon'
import InboxIcon from '@heroicons/vue/24/outline/InboxIcon'
import InformationCircleIcon from '@heroicons/vue/24/outline/InformationCircleIcon'
import LightBulbIcon from '@heroicons/vue/24/outline/LightBulbIcon'
import LockClosedIcon from '@heroicons/vue/24/outline/LockClosedIcon'
import LockOpenIcon from '@heroicons/vue/24/outline/LockOpenIcon'
import MagnifyingGlassMinusIcon from '@heroicons/vue/24/outline/MagnifyingGlassMinusIcon'
import MagnifyingGlassPlusIcon from '@heroicons/vue/24/outline/MagnifyingGlassPlusIcon'
import MapPinIcon from '@heroicons/vue/24/outline/MapPinIcon'
import MinusCircleIcon from '@heroicons/vue/24/outline/MinusCircleIcon'
import MinusSmallIcon from '@heroicons/vue/24/outline/MinusSmallIcon'
import PencilSquareIcon from '@heroicons/vue/24/outline/PencilSquareIcon'
import PlusCircleIcon from '@heroicons/vue/24/outline/PlusCircleIcon'
import PlusSmallIcon from '@heroicons/vue/24/outline/PlusSmallIcon'
import PrinterIcon from '@heroicons/vue/24/outline/PrinterIcon'
import QrCodeIcon from '@heroicons/vue/24/outline/QrCodeIcon'
import ReceiptPercentIcon from '@heroicons/vue/24/outline/ReceiptPercentIcon'
import ShareIcon from '@heroicons/vue/24/outline/ShareIcon'
import TicketIcon from '@heroicons/vue/24/outline/TicketIcon'
import TrashIcon from '@heroicons/vue/24/outline/TrashIcon'
import UserCircleIcon from '@heroicons/vue/24/outline/UserCircleIcon'
import UserIcon from '@heroicons/vue/24/outline/UserIcon'
import WalletIcon from '@heroicons/vue/24/outline/WalletIcon'
import WrenchScrewdriverIcon from '@heroicons/vue/24/outline/WrenchScrewdriverIcon'
import XMarkIcon from '@heroicons/vue/24/outline/XMarkIcon'

export const ICONS_LIST = {
    ArrowLeftOnRectangleIcon,
    ArrowRightIcon,
    ArrowRightOnRectangleIcon,
    ArrowSmallLeftIcon,
    BanknotesIcon,
    Bars3Icon,
    BugAntIcon,
    BuildingStorefrontIcon,
    CalendarDaysIcon,
    CameraIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    CheckCircleIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ClipboardDocumentListIcon,
    ClockIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    CurrencyDollarIcon,
    CurrencyYenIcon,
    DocumentTextIcon,
    EllipsisHorizontalCircleIcon,
    EnvelopeIcon,
    EnvelopeOpenIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    FingerPrintIcon,
    GiftIcon,
    GlobeAltIcon,
    HomeIcon,
    InboxIcon,
    InformationCircleIcon,
    LightBulbIcon,
    LockClosedIcon,
    LockOpenIcon,
    MagnifyingGlassMinusIcon,
    MagnifyingGlassPlusIcon,
    MapPinIcon,
    MinusCircleIcon,
    MinusSmallIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    PlusSmallIcon,
    PrinterIcon,
    QrCodeIcon,
    ReceiptPercentIcon,
    ShareIcon,
    TicketIcon,
    TrashIcon,
    UserCircleIcon,
    UserIcon,
    WalletIcon,
    WrenchScrewdriverIcon,
    XMarkIcon,
}

export default defineComponent({
    name: 'KIcon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        size: {
            type: [Number, String],
            required: false,
            default: 24,
        },
    },
    data() {
        return {
            ICONS_LIST,
        }
    },
})
</script>

<template>
    <component v-if="ICONS_LIST[icon]" :is="ICONS_LIST[icon]" :style="{ height: `${size}px` }" />
</template>
