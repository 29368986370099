<script>
import { mapGetters } from 'vuex'

import KMountedMixin from '@/mixins/KMountedMixin.vue'
import MainMenu from './MainMenu.vue'
import ModalWithFooter from '@/components/ModalWithFooter.vue'

export default {
    mixins: [KMountedMixin],
    components: { ModalWithFooter, MainMenu },
    computed: {
        ...mapGetters({
            is_whitelabel_mode: 'is_whitelabel_mode',

            slug: 'project/slug',
            logo: 'logo',
        }),
    },
}
</script>

<template>
    <ModalWithFooter class="bg-primary--background">
        <template #trigger="params">
            <slot name="trigger" v-bind="params" />
        </template>
        <div class="h-full flex flex-col">
            <div
                class="flex items-center justify-between mx-auto p-4 w-full border-b border-primary--border h-14 md:h-16"
            >
                <div>
                    <img v-if="is_whitelabel_mode" :src="logo" class="w-10 block" />
                    <img v-if="!is_whitelabel_mode" src="/images/kinchaku_logo.svg" class="block" />
                </div>
                <div></div>
            </div>
            <div class="flex-1">
                <MainMenu class="h-full" />
            </div>
        </div>
    </ModalWithFooter>
</template>
