<script>
import { mapGetters, mapActions } from 'vuex'

import { KAvatar, KSpinner, KDropDown } from '@/components/_base'
import MainMenu from '@/components/menu/MainMenu.vue'
import MainMenuModal from '@/components/menu/MainMenuModal.vue'
import AccessToAccountModal from '@/components/account/AccessToAccountModal.vue'
import CookiesConsentModal from '@/components/CookiesConsentModal.vue'
import RequestQueued from '@/components/errors/RequestQueued.vue'
import PageNotFound from '@/components/errors/PageNotFound.vue'
import ServiceUnavailable from '@/components/errors/ServiceUnavailable.vue'
import Footer from '@/components/Footer.vue'
import ProjectTermsAndPrivacyDropdown from '@/components/project/ProjectTermsAndPrivacyDropdown.vue'

export default {
    components: {
        KAvatar,
        KSpinner,
        KDropDown,
        MainMenu,
        MainMenuModal,
        CookiesConsentModal,
        AccessToAccountModal,
        ProjectTermsAndPrivacyDropdown,
        PageNotFound,
        RequestQueued,
        ServiceUnavailable,
        Footer,
    },
    data() {
        return {
            mainMenuShown: false,
        }
    },
    computed: {
        ...mapGetters({
            is_preloaded: 'is_preloaded',
            is_full_404_error: 'is_full_404_error',
            is_full_503_error: 'is_full_503_error',
            is_full_loader: 'is_full_loader',
            is_account_loader: 'is_account_loader',
            is_whitelabel_mode: 'is_whitelabel_mode',
            show_access_to_workspace_modal: 'show_access_to_workspace_modal',
            retry_timer: 'retry_timer',

            authenticated: 'account/authenticated',

            logo: 'logo',
            brand_name: 'brand_name',

            slug: 'project/slug',
            have_project: 'project/have_project',
            project_brand_name: 'project/project_brand_name',
        }),
        transition_props() {
            return {
                'enter-active-class': 'duration-300 ease-out',
                'enter-from-class': 'transform opacity-0',
                'enter-to-class': 'opacity-100',
                'leave-active-class': 'duration-200 ease-in',
                'leave-from-class': 'opacity-100',
                'leave-to-class': 'transform opacity-0',
            }
        },
    },
    beforeRouteUpdate() {
        this.mainMenuShown = false
    },
    methods: {
        ...mapActions({
            goToIndexPage: 'goToIndexPage',
        }),
        showMainMenu() {
            this.mainMenuShown = true
        },
        hideMainMenu() {
            this.mainMenuShown = false
        },
    },
}
</script>

<template>
    <router-view v-slot="{ Component }">
        <Transition
            enter-active-class="duration-300 ease-out"
            enter-from-class="transform opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-200 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="transform opacity-0"
        >
            <RequestQueued v-if="retry_timer" />
            <ServiceUnavailable v-else-if="is_full_503_error" />
            <div
                v-else-if="is_preloaded"
                class="absolute top-0 right-0 bottom-0 left-0 h-full flex flex-col bg-primary--background overflow-y-auto"
            >
                <div class="print:hidden border-primary--border bg-white">
                    <div
                        class="flex items-center justify-between max-w-[1800px] mx-auto px-4 h-14 md:h-16 md:px-16 relative border-b border-primary--border"
                    >
                        <div>
                            <div v-if="is_whitelabel_mode" class="flex items-center">
                                <img :src="logo" class="w-10 h-10 block" />
                                <div class="font-bold ml-3">{{ brand_name }}</div>
                            </div>
                            <img
                                v-if="!is_whitelabel_mode"
                                src="/images/kinchaku_logo.svg"
                                class="block"
                            />
                        </div>
                        <div>
                            <div class="-m-4">
                                <KSpinner v-if="is_account_loader" :size="24" class="mt-2 mr-4" />
                                <template v-else>
                                    <MainMenuModal v-if="$root.isMobile" v-model="mainMenuShown">
                                        <template #trigger="{ open }">
                                            <KButton
                                                @click="open"
                                                class="text-primary--graphic md:hidden border-0"
                                                icon="Bars3Icon"
                                                mode="icon"
                                            />
                                        </template>
                                    </MainMenuModal>
                                    <KDropDown v-if="authenticated">
                                        <template #trigger="{ click, show }">
                                            <KButton
                                                @click="click"
                                                :class="{ 'text-link': show }"
                                                class="capitalize hidden md:flex"
                                                iconLeft="UserCircleIcon"
                                                mode="text"
                                            >
                                                {{ $t('my account') }}
                                            </KButton>
                                        </template>
                                        <div class="w-80 hidden md:block">
                                            <MainMenu @signOut="() => hideMainMenu()" />
                                        </div>
                                    </KDropDown>
                                    <KButton
                                        v-else
                                        :to="
                                            slug
                                                ? { name: 'projectSignInPage', params: { slug } }
                                                : { name: 'signInPage' }
                                        "
                                        class="hidden md:flex"
                                        iconLeft="ArrowLeftOnRectangleIcon"
                                        mode="text"
                                    >
                                        {{ $t('sign in') }}
                                    </KButton>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="
                            have_project &&
                            (!is_whitelabel_mode ||
                                (project_brand_name && project_brand_name !== brand_name))
                        "
                        :class="{
                            'md:border-b bg-primary--background md:bg-indigo-light--background':
                                $route.name === 'projectIndexPage',
                            'border-b bg-indigo-light--background':
                                $route.name !== 'projectIndexPage',
                        }"
                        class="border-primary--border"
                    >
                        <div
                            class="flex items-center justify-between max-w-[1800px] mx-auto px-4 h-14 md:h-16 md:px-16"
                        >
                            <router-link
                                :to="
                                    slug
                                        ? { name: 'projectIndexPage', params: { slug } }
                                        : { name: 'indexPage' }
                                "
                                class="flex items-center cursor-pointer"
                            >
                                <KAvatar
                                    v-if="logo && !is_whitelabel_mode"
                                    :src="logo"
                                    class="mr-3"
                                />
                                <div class="font-bold">
                                    {{ is_whitelabel_mode ? project_brand_name : brand_name }}
                                </div>
                            </router-link>
                            <div>
                                <ProjectTermsAndPrivacyDropdown v-if="!is_whitelabel_mode" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- :class="{ 'md:justify-center': !$slots['second'] }" -->

                <div class="flex-1 flex flex-col relative">
                    <Transition
                        enter-active-class="duration-300 ease-out"
                        enter-from-class="transform opacity-0"
                        enter-to-class="opacity-100"
                    >
                        <div
                            v-if="is_full_loader"
                            class="absolute top-0 left-0 right-0 bottom-0 bg-indigo-light--background flex items-center justify-center"
                        >
                            <KSpinner :size="64" />
                        </div>
                        <PageNotFound v-else-if="is_full_404_error" />
                        <component v-else :is="Component" />
                    </Transition>
                </div>
                <Footer class="print:hidden" />
                <AccessToAccountModal v-model="show_access_to_workspace_modal" />
                <CookiesConsentModal />
            </div>
            <div
                v-else
                class="absolute top-0 left-0 right-0 bottom-0 bg-indigo-light--background flex items-center justify-center"
            >
                <KSpinner :size="64" />
            </div>
        </Transition>
    </router-view>
</template>
