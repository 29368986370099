import api from '@/api'

export default {
    namespaced: true,
    state: {
        pass: undefined,
    },
    getters: {
        pass(state) {
            return state.pass
        },
        shortuuid(state) {
            return state.pass?.shortuuid
        },
    },
    mutations: {
        SET_PASS(state, value) {
            state.pass = value
        },
    },
    actions: {
        issue({ rootGetters }, data) {
            const campaign = rootGetters['campaign']

            if (campaign) data.campaign = campaign

            return api.post(`/${rootGetters['project/project'].id}/issue`, data)
        },
        loadPass({ commit, dispatch }, shortuuid) {
            return api.get(`/${shortuuid}/pass`).then(data => {
                commit('SET_PASS', data.wallet_pass)
                dispatch('setStore', data.store, { root: true })

                return data.wallet_pass
            })
        },
        loadAllPasses(context, pagination = {}) {
            return api.post('/passes', { ...pagination })
        },
        loadSignedPass({ rootGetters }, { pass, signature }) {
            return api
                .get(`/${rootGetters['project/project'].id}/${pass}/self`, {
                    params: { signature },
                })
                .then(({ wallet_pass }) => wallet_pass)
        },
        loadPasses({ commit, rootGetters }, params) {
            return api
                .post(`/${rootGetters['project/project'].id}/passes`, params)
                .then(({ data }) => {
                    if (data && data.length) {
                        commit('account/SET_USER_PASSES', data, { root: true })
                    }
                    return data
                })
        },
        cancelPass({ rootGetters }, { id, reason }) {
            return api.post(`/${rootGetters['project/project'].id}/cancel/${id}`, { reason })
        },
        extendPass({ rootGetters }, { id, duration }) {
            return api.post(`/${rootGetters['project/project'].id}/extend/${id}`, { duration })
        },
        rotatePin({ rootGetters }, id) {
            return api.post(`/${rootGetters['project/project'].id}/rotate/${id}`)
        },
        getPassActivity({ rootGetters }, { pagination }) {
            return api.get(`/${rootGetters['project/project'].id}/passes/activity`, {
                params: { ...pagination },
            })
        },
        modifyBooking({ rootGetters }, { passId, data }) {
            return api.patch(`/${rootGetters['project/project'].id}/book/${passId}`, data)
        },
        bookByPass({ rootGetters }, { passId, data }) {
            return api.post(`/${rootGetters['project/project'].id}/book/${passId}`, data)
        },
    },
}
