import uniqBy from 'lodash/uniqBy'

import router from '@/router'

export default {
    namespaced: true,
    state: {
        issuingStarted: false,
        goods: [],
        dcChecked: false,
        fare: undefined,
        groupPasses: undefined,
        passCode: undefined,
        booking: {
            time: undefined,
            unit: undefined,
            orderSent: false,
        },
        // temp hack for linked reservations before we introduce Order
        second_bookings: [],
    },
    getters: {
        issuing_started(state) {
            return state.issuingStarted
        },
        dc_checked(state) {
            return state.dcChecked
        },
        goods(state) {
            return state.goods || []
        },
        fare(state) {
            return state.fare
        },
        pass_code(state) {
            return state.passCode
        },
        full_fare(state, getters, rootState, rootGetters) {
            if (!getters['fare']) return

            const { origin, destination } = getters['fare']

            return rootGetters['project/fares'].find(
                f =>
                    (f.origin_id === origin && f.destination_id === destination) ||
                    (f.origin_id === destination && f.destination_id === origin)
            )
        },
        stations(state, getters, rootState, rootGetters) {
            if (!getters['fare']) return

            return {
                origin: rootGetters['project/stations'].find(s => s.id === getters['fare'].origin),
                destination: rootGetters['project/stations'].find(
                    s => s.id === getters['fare'].destination
                ),
            }
        },
        booking(state) {
            return state.booking
        },
        second_bookings(state) {
            return state.second_bookings
        },
        booking_unit(state) {
            return state.booking.unit
        },
        booking_time(state) {
            return state.booking.time
        },
        goods_by_id(state, getters) {
            return getters.goods.reduce((end, item) => ({ ...end, [item.id]: item }), {})
        },
        total_goods(state, getters) {
            return getters.goods?.reduce((sum, item) => sum + item.quantity, 0)
        },
        total_amount(state, getters, rootState, rootGetters) {
            return getters.goods?.reduce((sum, item) => {
                const plan = rootGetters['project/plans_by_id'][item.id]
                return sum + item.quantity * plan.amount + plan.addons_without_tax + plan.addons_tax
            }, 0)
        },
        tax_amount(state, { total_amount }, rootState, { tax_percent }) {
            return total_amount - Math.round((total_amount * tax_percent) / (1 + tax_percent))
        },
        allow_grouping(state, getters, rootState, rootGetters) {
            const isGroup = rootGetters['project/with_group_pass'] && getters.total_goods > 1

            if (!isGroup) return false

            return rootGetters['project/is_lift']
                ? uniqBy(getters.goods, 'roundtrip').length === 1
                : true
        },
        group_passes(state) {
            return state.groupPasses
        },
        dynamic_pricing(state, getters, rootState, rootGetters) {
            if (rootGetters['project/is_whole_line']) {
                return rootGetters?.['project/fares']?.[0]?.plans || {}
            } else if (
                getters['full_fare']?.plans &&
                Object.keys(getters['full_fare']?.plans).length
            ) {
                return getters['full_fare']?.plans || {}
            }

            return getters['booking_time']?.categories || {}
        },
        with_reservation(state, getters, rootState, rootGetters) {
            return (
                (rootGetters['project/with_reservation'] || rootGetters['project/is_booking']) &&
                !rootGetters['project/is_voucher']
            )
        },
        with_activation_date(state, getters, rootState, rootGetters) {
            return (
                rootGetters['project/is_transport'] &&
                !(
                    getters['with_reservation'] ||
                    rootGetters['project/project']?.with_next_month_only ||
                    rootGetters['project/project']?.start
                )
            )
        },
        need_second_bookings(state, getters, rootState, rootGetters) {
            return !!(
                rootGetters['project/with_linked_bundle'] &&
                rootGetters['project/booking_units'].length > 1 &&
                getters['booking_time']?.start
            )
        },
        booking_venue(state, getters, rootState, rootGetters) {
            const venueId = getters['booking_unit']?.venue_id

            return rootGetters['project/venues'].find(v => v.id === venueId)
        },

        has_passes_in_single_pass_project(state, getters, rootState, rootGetters) {
            return (
                rootGetters['user/passes_list'] &&
                rootGetters['project/single_pass_project'] &&
                rootGetters['user/passes_list'].length
            )
        },
        need_pass_code(state, getters, rootState, { with_pass_code }) {
            return with_pass_code && !getters.pass_code
        },
        need_goods(state, getters, rootState, rootGetters) {
            return rootGetters['project/plans']?.length && !getters.total_goods
        },
        need_booking_unit(state, getters) {
            return getters.with_reservation && !getters.booking_unit
        },
        need_booking_time(state, getters) {
            return getters.with_reservation && !getters.booking_time
        },
        need_authorization(state, getters, rootState, rootGetters) {
            return rootGetters['project/has_registration'] && !rootGetters['account/authenticated']
        },
        need_data_collection(state, getters, rootState, rootGetters) {
            if (rootGetters['project/has_data_collection']) {
                // if (rootGetters['project/with_kyc']) return true
                if (getters.dc_checked) return false

                return !rootGetters['account/data_collection_complete']
            }

            return false
        },
        need_fare(state, getters, rootState, rootGetters) {
            return rootGetters['project/is_transport'] && !getters.fare
        },
    },
    mutations: {
        UPDATE_PASS_CODE(state, value) {
            state.passCode = value
        },
        UPDATE_DC_CHECK(state, value) {
            state.dcChecked = value
        },
        UPDATE_CART_GOODS(state, goods) {
            state.goods = goods
        },
        UPDATE_CART_FARE(state, fare) {
            state.fare = fare
        },
        UPDATE_CART_BOOKING_UNIT(state, unit) {
            state.booking.unit = unit
        },
        UPDATE_CART_BOOKING_TIME(state, time) {
            state.booking.time = time
        },
        UPDATE_BOOKING_ORDER_SENT(state, value) {
            state.booking.orderSent = value
        },
        UPDATE_CART_GROUP_PASSES(state, value) {
            state.groupPasses = value
        },
        UPDATE_ISSUING_STARTED(state, value) {
            state.issuingStarted = value
        },
        UPDATE_SECOND_BOOKINGS(state, value) {
            state.second_bookings = value
        },
    },
    actions: {
        setPassCode({ commit }, code) {
            commit('UPDATE_PASS_CODE', code)
        },
        setGoods({ commit }, goods) {
            commit('UPDATE_CART_GOODS', goods)
        },
        setFare({ commit }, fare) {
            commit('UPDATE_CART_FARE', fare)
        },
        setBookingUnit({ commit }, unit) {
            commit('UPDATE_CART_BOOKING_UNIT', unit)
        },
        setBookingTime({ commit }, time) {
            commit('UPDATE_CART_BOOKING_TIME', time)
        },
        setDCCheck({ commit }, value) {
            commit('UPDATE_DC_CHECK', value)
        },
        setCart({ commit }, cart) {
            commit('UPDATE_CART_GOODS', cart?.items || [])
        },
        setGroupPasses({ commit }, value) {
            commit('UPDATE_CART_GROUP_PASSES', value)
        },
        removeGoods({ commit, getters }, id) {
            commit(
                'UPDATE_CART_GOODS',
                getters['goods'].filter(cartItem => cartItem.id !== id)
            )
        },
        setBookingOrderSent({ commit }, value) {
            commit('UPDATE_BOOKING_ORDER_SENT', value)
        },
        setSecondBookings({ commit }, value) {
            commit('UPDATE_SECOND_BOOKINGS', value)
        },
        resetCart({ commit }) {
            commit('UPDATE_ISSUING_STARTED', false)
            commit('UPDATE_CART_GOODS', [])
            commit('UPDATE_CART_BOOKING_UNIT', undefined)
            commit('UPDATE_CART_BOOKING_TIME', undefined)
            commit('UPDATE_BOOKING_ORDER_SENT', false)
            commit('UPDATE_SECOND_BOOKINGS', [])
            commit('UPDATE_CART_GROUP_PASSES', undefined)
            commit('UPDATE_CART_FARE', undefined)
            commit('UPDATE_DC_CHECK', false)
        },
        async continueIssuingPass({ getters, rootGetters, dispatch, commit }, { replace } = {}) {
            if (!rootGetters['project/is_published']) {
                return dispatch('goToIndexPage', undefined, { root: true })
            }

            const slug = rootGetters['project/slug']
            const navigate = replace ? router.replace : router.push

            let orderDate = new Date()
            orderDate.setMilliseconds(0)
            orderDate = orderDate.toISOString()

            if (!getters['issuing_started']) commit('UPDATE_ISSUING_STARTED', true)

            if (getters.has_passes_in_single_pass_project) {
                return navigate({ name: 'projectPassesPage', params: { slug } })
            }

            if (rootGetters['account/need_connection_to_workspace']) {
                return dispatch('showAccessToWorkspaceModal', undefined, { root: true })
            }

            if (getters.need_pass_code) {
                return navigate({ name: 'projectCodePage', params: { slug } })
            }
            if (rootGetters['project/with_kyc'] && getters.need_authorization) {
                return navigate({ name: 'projectSignInPage', params: { slug } })
            }

            if (rootGetters['project/with_kyc'] && !rootGetters['account/is_kyc_done']) {
                return navigate({ name: 'projectRegistrationPage', params: { slug } })
            } else if (rootGetters['project/with_kyc'] && rootGetters['account/is_kyc_done']) {
                commit('UPDATE_DC_CHECK', true)
            }

            if (getters.need_booking_unit) {
                return navigate({ name: 'projectBookingPage', params: { slug } })
            }

            if (getters.need_booking_time) {
                return navigate({ name: 'projectAvailabilityPage', params: { slug } })
            }

            if (getters.need_goods && getters.with_activation_date) {
                if (
                    rootGetters['project/plans']?.length === 1 &&
                    rootGetters['project/order_limit'] === 1 &&
                    !rootGetters['project/with_seatmap']
                ) {
                    dispatch('setGoods', [{ id: rootGetters['project/plans'][0].id, quantity: 1 }])
                } else {
                    return navigate({ name: 'projectCategoriesPage', params: { slug } })
                }
            }

            if (getters.need_fare) {
                if (
                    rootGetters['project/is_whole_line'] &&
                    rootGetters['project/fares']?.[0]?.origin_id
                ) {
                    dispatch('setFare', {
                        origin: rootGetters['project/fares'][0].origin_id,
                        destination: rootGetters['project/fares'][0].destination_id,
                    })
                } else {
                    return navigate({ name: 'projectFarePage', params: { slug } })
                }
            }

            if (getters.need_goods) {
                if (
                    rootGetters['project/plans']?.length === 1 &&
                    rootGetters['project/order_limit'] === 1 &&
                    !rootGetters['project/with_seatmap']
                ) {
                    dispatch('setGoods', [{ id: rootGetters['project/plans'][0].id, quantity: 1 }])
                } else {
                    return navigate({ name: 'projectCategoriesPage', params: { slug } })
                }
            }

            if (rootGetters['project/with_lottery'] && !rootGetters['account/has_won_lottery']) {
                return navigate({ name: 'projectLotteryPage', params: { slug } })
            }

            if (getters.need_authorization) {
                return navigate({ name: 'projectSignInPage', params: { slug } })
            }

            if (getters.need_data_collection) {
                return navigate({ name: 'projectRegistrationPage', params: { slug } })
            }

            if (getters['total_amount']) {
                return navigate({ name: 'projectPaymentPage', params: { slug } })
            }

            if (getters['total_goods']) {
                await dispatch('makeOrder')
            } else {
                const pass = await dispatch(
                    'pass/issue',
                    {
                        campaign: rootGetters['campaign'],
                        pass_code: getters['pass_code'],
                    },
                    { root: true }
                )

                dispatch('resetCart')

                if (pass && pass?.shortuuid) {
                    return navigate({
                        name: 'passIndexPage',
                        params: { pass_short_uuid: pass.shortuuid },
                    })
                }
            }

            if (rootGetters['project/booking_need_confirmation']) {
                router.replace({
                    name: 'projectPassesNeedConfirmPage',
                    params: { slug },
                })
            } else {
                const query = { orderDate }

                if (rootGetters['project/is_multiple_booking']) {
                    query['passCount'] = 1 + getters['second_bookings'].length
                }

                return navigate({
                    name: 'projectPassesPage',
                    params: { slug },
                    query,
                })
            }
        },
        async makeOrder(
            { getters, rootGetters, dispatch },
            { token, meta, coupon, coupon_id, payment_method, ...data } = {}
        ) {
            let result

            const orderData = {
                pass_code: getters['pass_code'],
                cart: {
                    token,
                    meta,
                    coupon,
                    coupon_id,
                    payment_method,
                    items: getters['goods'],
                    fare: getters['fare'],
                    group_passes: getters['group_passes'],
                    booking: getters['booking']?.unit
                        ? {
                              ...getters['booking'].time,
                              unit: getters['booking'].unit.id,
                          }
                        : null,
                },
                campaign: rootGetters['campaign'],
                ...data,
            }

            if (!getters['booking']?.orderSent) {
                result = await dispatch('payment/order', { data: orderData }, { root: true })
            }

            dispatch('setBookingOrderSent', true)

            if (
                rootGetters['project/is_multiple_booking'] &&
                rootGetters['cart/second_bookings']?.length
            ) {
                for (const booking of rootGetters['cart/second_bookings']) {
                    if (booking.orderSent) continue

                    await dispatch(
                        'payment/order',
                        {
                            project: rootGetters['project/project'].id,
                            data: {
                                ...orderData,
                                cart: {
                                    ...orderData.cart,
                                    booking: { ...booking.time, unit: booking.unit.id },
                                },
                            },
                        },
                        { root: true }
                    )

                    booking.orderSent = true
                }
            }

            return result
        },
    },
}
