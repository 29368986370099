<script>
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

import FieldMixin from './mixins/FieldMixin.vue'
import FieldLayout from './layouts/FieldLayout.vue'

export default defineComponent({
    mixins: [FieldMixin],
    components: { FieldLayout },
    props: {
        items: { type: Array, required: true },
        itemText: { type: String, default: 'text' },
        itemValue: { type: String, default: 'value' },
        emptyItemTitle: { type: String, default: '' },
        modelValue: { type: [String, Number, Array], default: undefined },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { value, errorMessage, handleChange, handleBlur } = useField(
            props.name,
            props.rules,
            { initialValue: props.modelValue }
        )

        // Create a wrapper around handleChange to emit the update event
        const updateValue = newValue => {
            handleChange(newValue)
            emit('update:modelValue', newValue) // Emitting new value to the parent.
        }

        return {
            value,
            errorMessage,
            handleBlur,
            handleChange: event => {
                if (props.fieldAttrs?.multiple) {
                    const selectedOptions = Array.from(
                        event.target.selectedOptions,
                        option => option.value
                    )
                    updateValue(selectedOptions)
                } else {
                    updateValue(event.target.value)
                }
            },
        }
    },
    computed: {
        is_strings_items() {
            return this.items.some(v => typeof v === 'string')
        },
        class_list() {
            return {
                'bg-white': !this.disabled,
                'bg-primary--background text-secondary--text shadow-none': this.disabled,
            }
        },
    },
})
</script>

<template>
    <FieldLayout v-bind="$attrs" :name="name" :label="label" :is-required="is_required">
        <div class="flex flex-wrap items-stretch w-full relative">
            <select
                v-bind="fieldAttrs"
                v-model="value"
                @change="handleChange"
                @blur="handleBlur"
                :class="{
                    ...class_list,
                    'border-red': errorMessage || isErrored,
                    'text-secondary--text':
                        !!emptyItemTitle && (value === '' || value === undefined),
                }"
                :name="name"
                :placeholder="placeholder"
                :disabled="disabled"
                :size="fieldAttrs?.multiple ? Math.min(items.length || 1, 5) : 1"
                class="min-h-[48px] px-3 shadow border border-primary--border rounded-md w-full transition"
            >
                <option
                    v-if="emptyItemTitle"
                    disabled
                    value=""
                    :selected="value === '' || value === undefined"
                >
                    {{ emptyItemTitle }}
                </option>
                <option
                    v-for="item in items"
                    :key="is_strings_items ? item : item[itemValue]"
                    :value="is_strings_items ? item : item[itemValue]"
                >
                    {{ is_strings_items ? item : item[itemText] }}
                </option>
            </select>
            <div v-if="$slots['suffix']" class="absolute inset-y-0 right-8 flex items-center">
                <slot name="suffix" />
            </div>
        </div>
    </FieldLayout>
</template>
