<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            retry_timer: 'retry_timer',
        }),
    },
    methods: {
        play() {
            this.$refs['audio'].play()
        },
    },
}
</script>

<template>
    <div class="retry-timer bg-indigo-light--background">
        <div
            class="retry-timer-container flex flex-col items-center justify-center p-10 md:py-20 relative z-10 max-w-lg mx-auto min-h-screen pb-56 md:pb-80"
        >
            <div class="mb-10">
                <img src="/images/kinchaku_logo.svg" class="block bg-indigo-light--background" />
            </div>
            <h1 class="capitalize font-bold text-2xl mb-4">{{ $t('request queued') }}</h1>
            <div class="mb-4 text-secondary--text text-center">
                <template v-if="$root.is_japanese">
                    現在、サーバが大変混み合っております。
                    時間をおいてから再度ご利用くださいますよう、お願い申し上げます。
                </template>
                <template v-else>
                    Please wait a moment while we prepare the page for you.<br />
                    Thank you for your patience!
                </template>
            </div>
            <div class="rounded-full p-1 border-4 border-white">
                <div
                    class="w-20 h-20 bg-white rounded-full text-red font-bold flex items-center justify-center text-3xl"
                >
                    {{ retry_timer }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.retry-timer {
    height: 100%;
    overflow: hidden;
}
.retry-timer-container {
    background-image: url('/images/alarm-clocks.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: min(calc(100% - 64px), 378px);
}

.retry-timer::after {
    display: block;
    content: '';
    width: 100%;
    height: 100px;
    background-color: #fff;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    position: absolute;
    bottom: 0;
}
</style>
