import VueMatomo from 'vue-matomo'

import router from '../router'

export default {
    install(app) {
        try {
            if (import.meta.env.VITE_APP_ENV === 'production') {
                app.use(VueMatomo, {
                    // Configure your matomo server and site by providing

                    host: 'https://a.kinchaku.com',

                    siteId: 5,

                    // Overrides the autogenerated tracker endpoint entirely

                    // Default: undefined

                    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

                    // Overrides the autogenerated tracker script path entirely

                    // Default: undefined

                    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

                    // Enables automatically registering pageviews on the router

                    router,

                    // Enables link tracking on regular links. Note that this won't

                    // work for routing links (ie. internal Vue router links)

                    // Default: true

                    enableLinkTracking: true,

                    // Require consent before sending tracking information to matomo

                    // Default: false

                    requireConsent: true,

                    // Whether to track the initial page view

                    // Default: true

                    trackInitialView: true,

                    // Run Matomo without cookies

                    // Default: false

                    disableCookies: false,

                    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)

                    // Default: false

                    enableHeartBeatTimer: true,

                    // Set the heartbeat timer interval

                    // Default: 15

                    heartBeatTimerInterval: 15,

                    // Whether or not to log debug information

                    // Default: false

                    debug: false,

                    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)

                    // Default: undefined

                    userId: undefined,

                    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)

                    // Default: undefined, example '*.example.com'

                    cookieDomain: '*.kinchaku.me',

                    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'

                    // Default: undefined, example: '*.example.com'

                    domains: '*.kinchaku.me',

                    preInitActions: [],
                })
            }
        } catch (e) {
            /* eslint-disable no-console */
            console.warn('Matomo initialization error', e)
        }
    },
}
