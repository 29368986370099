<template>
    <div class="service-unavailable bg-indigo-light--background">
        <div
            class="service-unavailable-container flex flex-col items-center justify-center p-10 md:py-20 relative z-10 max-w-lg mx-auto min-h-screen pb-56 md:pb-80"
        >
            <div class="mb-10">
                <img src="/images/kinchaku_logo.svg" class="block bg-indigo-light--background" />
            </div>
            <h1 class="capitalize font-bold text-2xl mb-4">
                {{ $t('under maintenance') }}
            </h1>
            <div class="mb-4 text-secondary--text text-center">
                <template v-if="$root.is_japanese">
                    現在、サービスはメンテナンス中です。もうすぐ戻ります！
                </template>
                <template v-else>
                    The service is currently undergoing maintenance. We'll be back soon!
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.service-unavailable {
    height: 100%;
    overflow: hidden;
}
.service-unavailable-container {
    background-image: url('/images/gears.png');
    background-repeat: no-repeat;
    background-position: center bottom 64px;
    background-size: min(calc(100% - 128px), 289px);
}

.service-unavailable::after {
    display: block;
    content: '';
    width: 100%;
    height: 100px;
    background-color: #fff;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    position: absolute;
    bottom: 0;
}
</style>
