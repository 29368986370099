<script>
import { mapGetters } from 'vuex'
import { KDropDown } from '@/components/_base'

export default {
    components: { KDropDown },
    computed: {
        ...mapGetters({
            to_terms_url: 'project/to_terms_url',
            to_privacy_url: 'project/to_privacy_url',
            cancelation_url: 'cancelation_url',
        }),
    },
}
</script>

<template>
    <KDropDown v-if="to_terms_url || to_privacy_url || cancelation_url">
        <template #trigger="{ click }">
            <KButton
                @click="click"
                icon="EllipsisHorizontalCircleIcon"
                mode="icon"
                class="-my-2 -mx-3"
            />
        </template>
        <template v-slot="{ close }">
            <div class="py-2">
                <KButton
                    v-if="to_terms_url"
                    v-bind="to_terms_url"
                    @click="close"
                    mode="text"
                    class="whitespace-nowrap text-sm text-secondary--text w-full py-2 px-4"
                    target="_blank"
                >
                    {{ $t('terms and conditions') }}
                </KButton>
                <KButton
                    v-if="to_privacy_url"
                    v-bind="to_privacy_url"
                    @click="close"
                    mode="text"
                    class="whitespace-nowrap text-sm text-secondary--text w-full py-2 px-4"
                    target="_blank"
                >
                    {{ $t('privacy policy') }}
                </KButton>
                <KButton
                    v-if="cancelation_url"
                    :href="cancelation_url"
                    @click="close"
                    mode="text"
                    class="whitespace-nowrap text-sm text-secondary--text w-full py-2 px-4"
                    target="_blank"
                >
                    {{ $t('cancelation policy') }}
                </KButton>
            </div>
        </template>
    </KDropDown>
</template>
