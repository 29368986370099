<script>
import { defineComponent } from 'vue'
import { Form as ValidationForm } from 'vee-validate'

export default defineComponent({
    name: 'KForm',
    components: {
        ValidationForm,
    },
    computed: {
        fields() {
            return this.$refs['form']?.getValues
                ? Object.keys(this.$refs['form'].getValues()) || []
                : []
        },
    },
    methods: {
        setFieldsErrors(errors) {
            Object.keys(errors).forEach(key => {
                this.$refs['form'].setFieldError(key, errors[key])
            })
        },
    },
})
</script>

<template>
    <ValidationForm v-bind="$attrs" v-slot="slotData" ref="form">
        <slot v-bind="slotData" />
    </ValidationForm>
</template>
