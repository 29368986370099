<script>
import { KModal } from '@/components/_base'

import Footer from '@/components/Footer.vue'

export default {
    components: { KModal, Footer },
}
</script>

<template>
    <KModal v-bind="$attrs" xs-full inside-full>
        <template #trigger="params">
            <slot name="trigger" v-bind="params" />
        </template>
        <template #default="params">
            <div class="h-full flex flex-col">
                <div class="flex-1 overflow-y-auto relative">
                    <slot v-bind="params" />
                </div>
                <Footer v-if="$root.isMobile" />
            </div>
        </template>
    </KModal>
</template>
