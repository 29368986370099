<template>
    <div :class="class_list" class="rounded-md overflow-hidden text-left text-white p-4">
        <div class="flex items-center justify-between">
            <KIcon icon="ExclamationCircleIcon" class="h-6 w-6" />
            <div class="mx-4">
                <div v-if="toast.title" class="font-medium">{{ toast.title }}</div>
                <div v-if="toast.message" class="text-sm">{{ toast.message }}</div>
            </div>
            <KButton
                class="-m-4 hover:text-white"
                mode="icon"
                icon="XMarkIcon"
                @click="$emit('close')"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import KIcon from './KIcon.vue'
import KButton from './KButton.vue'

export default defineComponent({
    name: 'KToast',
    components: { KIcon, KButton },
    props: {
        toast: {
            type: Object,
            required: true,
        },
    },
    emits: ['close'],
    computed: {
        class_list() {
            return {
                'bg-green': this.toast.type === 'success',
                'bg-red': this.toast.type === 'error',
                'bg-yellow': this.toast.type === 'warning',
            }
        },
    },
})
</script>
