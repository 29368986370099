<script>
import { mapGetters } from 'vuex'
import { KAvatar } from '@/components/_base'

export default {
    name: 'AccountMenu',
    components: { KAvatar },
    props: {
        avatar: {
            type: String,
            default: '',
        },
        email: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            user: 'account/user',
            contact_image: 'account/contact_image',
        }),
        user_email() {
            return this.email || this.user?.email
        },
        image() {
            return this.avatar || this.contact_image
        },
    },
}
</script>

<template>
    <div v-if="user_email" class="flex items-center">
        <KAvatar v-if="image" :src="image" class="mr-3" />
        <div>
            <div class="text-secondary--text text-xs capitalize">
                {{ $t('email') }}
            </div>
            <div class="text-sm truncate">{{ user_email }}</div>
        </div>
    </div>
</template>
