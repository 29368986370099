<script>
import { mapActions } from 'vuex'

export default {
    methods: {
        ...mapActions({
            hideFull404Error: 'hideFull404Error',
            goToIndexPage: 'goToIndexPage',
        }),
        goHome() {
            this.goToIndexPage()
            this.hideFull404Error()
        },
    },
}
</script>

<template>
    <div class="m-auto text-center">
        <h1 class="text-9xl font-bold text-brand">404</h1>
        <p class="m-auto text-lg text-secondary--text">
            {{ $t('Page Not Found') }}
        </p>
        <KButton @click="goHome" class="my-4 w-full" mode="secondary" icon-left="HomeIcon">
            {{ $t('home') }}
        </KButton>
    </div>
</template>
