import { createRouter, createWebHistory } from 'vue-router'
import { isEmpty } from 'lodash'

import AppLayout from '@/layouts/AppLayout.vue'
import store from '../store'
import { hasToken } from '../auth'

const indexPage = () => import('@/pages/index.vue')
const walletPage = () => import('@/pages/wallet/index.vue')
const accountInformationPage = () => import('@/pages/account/information.vue')
const accountPasswordPage = () => import('@/pages/account/password.vue')
const accountPaymentHistoryPage = () => import('@/pages/account/payment-history.vue')
const accountPaymentMethodPage = () => import('@/pages/account/payment-method.vue')
const termsPage = () => import('@/pages/terms.vue')
const privacyPage = () => import('@/pages/privacy.vue')
const error404Page = () => import('@/pages/errors/404.vue')

const signInPage = () => import('@/pages/signin.vue')
const changePasswordPage = () => import('@/pages/change-password/index.vue')
const emailConfirmationPage = () => import('@/pages/email-confirmation.vue')
const emailVerifiedPage = () => import('@/pages/email-verified.vue')

const projectIndexPage = () => import('@/pages/project/index.vue')
const projectRegistrationPage = () => import('@/pages/project/registration.vue')
const projectLotteryPage = () => import('@/pages/project/lottery.vue')
const projectCodePage = () => import('@/pages/project/code.vue')
const projectCategoriesPage = () => import('@/pages/project/categories.vue')
const projectFarePage = () => import('@/pages/project/fare.vue')
const projectBookingPage = () => import('@/pages/project/booking.vue')
const projectAvailabilityPage = () => import('@/pages/project/availability.vue')
const projectPaymentPage = () => import('@/pages/project/payment.vue')
const projectSelfCheckInPage = () => import('@/pages/project/self-checkin.vue')
const projectStaticPage = () => import('@/pages/project/static.vue')
const projectPassesPage = () => import('@/pages/project/passes.vue')
const projectPassesNeedConfirmPage = () => import('@/pages/project/passes-need-confirm.vue')
const projectUpdateDCPage = () => import('@/pages/project/update-dc.vue')

const passIndexPage = () => import('@/pages/pass/index.vue')
const passAvailabilityPage = () => import('@/pages/pass/availability.vue')
const passDetailsPage = () => import('@/pages/pass/details/index.vue')

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/',
                name: 'indexPage',
                component: indexPage,
                meta: {
                    witeLabelRedirect: () => {
                        const slug = store.getters['project/slug']

                        if (store.getters['account/authenticated']) {
                            return { name: 'walletPage' }
                        } else if (slug) {
                            return { name: 'projectIndexPage', params: { slug } }
                        }
                    },
                },
            },
            {
                path: '/wallet',
                name: 'walletPage',
                component: walletPage,
                meta: { authRequired: true },
            },
            {
                path: '/account/customer-information',
                name: 'accountInformationPage',
                component: accountInformationPage,
                meta: { authRequired: true },
            },
            {
                path: '/account/password',
                name: 'accountPasswordPage',
                component: accountPasswordPage,
                meta: { authRequired: true },
            },
            {
                path: '/account/payment-history',
                name: 'accountPaymentHistoryPage',
                component: accountPaymentHistoryPage,
                meta: { authRequired: true },
            },
            {
                path: '/account/payment-method',
                name: 'accountPaymentMethodPage',
                component: accountPaymentMethodPage,
                meta: { authRequired: true },
            },
            {
                path: '/terms',
                name: 'termsPage',
                component: termsPage,
            },
            {
                path: '/privacy',
                name: 'privacyPage',
                component: privacyPage,
            },
            {
                path: '/signin',
                name: 'signInPage',
                component: signInPage,
                meta: { noAuthRequired: true },
            },
            {
                path: '/change-password',
                name: 'changePasswordPage',
                component: changePasswordPage,
                meta: { noAuthRequired: true },
            },
            {
                path: '/email-confirmation',
                name: 'emailConfirmationPage',
                component: emailConfirmationPage,
                meta: { authRequired: true },
            },
            {
                path: '/email-verified',
                name: 'emailVerifiedPage',
                component: emailVerifiedPage,
                meta: { authRequired: true },
            },
            {
                path: '/:slug/signin',
                name: 'projectSignInPage',
                component: signInPage,
                props: true,
                meta: { noAuthRequired: true },
            },
            {
                path: '/:slug/change-password',
                name: 'projectChangePasswordPage',
                component: changePasswordPage,
                props: true,
                meta: { noAuthRequired: true },
            },
            {
                path: '/:slug/email-confirmation',
                name: 'projectEmailConfirmationPage',
                component: emailConfirmationPage,
                props: true,
                meta: { authRequired: true },
            },
            {
                path: '/:slug/email-verified',
                name: 'projectEmailVerifiedPage',
                component: emailVerifiedPage,
                props: true,
                meta: { authRequired: true },
            },
            {
                path: '/:slug/registration',
                name: 'projectRegistrationPage',
                component: projectRegistrationPage,
                props: true,
                meta: { checkOrderPossibility: true },
            },
            {
                path: '/:slug/lottery',
                name: 'projectLotteryPage',
                component: projectLotteryPage,
                props: true,
                meta: { checkOrderPossibility: true, authRequired: true },
            },
            {
                path: '/:slug/code',
                name: 'projectCodePage',
                component: projectCodePage,
                props: true,
                meta: { checkOrderPossibility: true },
            },
            {
                path: '/:slug/categories/:plan_id?',
                name: 'projectCategoriesPage',
                component: projectCategoriesPage,
                props: true,
                meta: {
                    checkOrderPossibility: true,
                    checkBeforeEachRedirect: () => {
                        const slug = store.getters['project/slug']
                        const is_transport = store.getters['project/is_transport']
                        const with_reservation = store.getters['cart/with_reservation']
                        const cart_booking = store.getters['cart/booking']
                        const with_activation_date = store.getters['cart/with_activation_date']
                        const cart_fare = store.getters['cart/fare']

                        if (
                            store.getters['project/with_kyc'] &&
                            !store.getters['account/is_kyc_done']
                        ) {
                            return { name: 'projectRegistrationPage', params: { slug } }
                        }

                        if (
                            store.getters['project/with_lottery'] &&
                            !store.getters['account/has_won_lottery']
                        ) {
                            return { name: 'projectLotteryPage', params: { slug } }
                        }

                        if (with_reservation && (!cart_booking.unit || !cart_booking.time)) {
                            return {
                                name: 'projectBookingPage',
                                params: { slug },
                            }
                        }

                        if (is_transport && !with_activation_date && !cart_fare) {
                            return {
                                name: 'projectFarePage',
                                params: { slug },
                            }
                        }
                    },
                },
            },
            {
                path: '/:slug/fare',
                name: 'projectFarePage',
                component: projectFarePage,
                props: true,
                meta: {
                    checkOrderPossibility: true,
                    checkBeforeEachRedirect: () => {
                        const slug = store.getters['project/slug']
                        const plans_by_id = store.getters['project/plans_by_id']
                        const cart_goods = store.getters['cart/goods']
                        const with_activation_date = store.getters['cart/with_activation_date']
                        const selected_plan = plans_by_id?.[cart_goods?.[0]?.id]

                        if (
                            store.getters['project/with_kyc'] &&
                            !store.getters['account/is_kyc_done']
                        ) {
                            return { name: 'projectRegistrationPage', params: { slug } }
                        }

                        if (
                            store.getters['project/with_lottery'] &&
                            !store.getters['account/has_won_lottery']
                        ) {
                            return { name: 'projectLotteryPage', params: { slug } }
                        }

                        if (with_activation_date && !selected_plan) {
                            return {
                                name: 'projectCategoriesPage',
                                params: { slug },
                            }
                        }
                    },
                },
            },
            {
                path: '/:slug/booking',
                name: 'projectBookingPage',
                component: projectBookingPage,
                props: true,
                meta: {
                    checkOrderPossibility: true,
                    checkBeforeEachRedirect: () => {
                        const slug = store.getters['project/slug']

                        if (
                            store.getters['project/with_lottery'] &&
                            !store.getters['account/has_won_lottery']
                        ) {
                            return { name: 'projectLotteryPage', params: { slug } }
                        }

                        const is_bundle = store.getters['project/is_bundle']
                        const with_linked_bundle = store.getters['project/with_linked_bundle']
                        const booking_units = store.getters['project/booking_units']
                        const booking_unit = store.getters['cart/booking_unit']
                        const second_bookings = store.getters['cart/second_bookings']

                        if (is_bundle) {
                            if (with_linked_bundle) {
                                if (!booking_unit) {
                                    for (const [index, unit] of booking_units.entries()) {
                                        if (!index) store.dispatch('cart/setBookingUnit', unit)
                                        else if (
                                            !second_bookings.find(b => b.unit?.id === unit.id)
                                        ) {
                                            store.dispatch('cart/setSecondBookings', [
                                                ...second_bookings,
                                                { unit, time: {} },
                                            ])
                                        }
                                    }
                                }

                                return { name: 'projectAvailabilityPage', params: { slug } }
                            } else {
                                store.dispatch('cart/setBookingUnit', { unit: 'any' })
                                return { name: 'projectAvailabilityPage', params: { slug } }
                            }
                        } else if (!booking_unit && booking_units?.length === 1) {
                            store.dispatch('cart/setBookingUnit', booking_units[0])
                            return { name: 'projectAvailabilityPage', params: { slug } }
                        }
                    },
                },
            },
            {
                path: '/:slug/availability',
                name: 'projectAvailabilityPage',
                component: projectAvailabilityPage,
                props: true,
                meta: {
                    checkOrderPossibility: true,
                    checkBeforeEachRedirect: () => {
                        const slug = store.getters['project/slug']

                        if (
                            store.getters['project/with_lottery'] &&
                            !store.getters['account/has_won_lottery']
                        ) {
                            return { name: 'projectLotteryPage', params: { slug } }
                        }

                        const booking_units = store.getters['project/booking_units']
                        const is_bundle = store.getters['project/is_bundle']
                        const with_linked_bundle = store.getters['project/with_linked_bundle']
                        const booking_unit = store.getters['cart/booking_unit']

                        if (!booking_unit && is_bundle && !with_linked_bundle) {
                            store.dispatch('cart/setBookingUnit', { unit: 'any' })
                        } else if (
                            !booking_unit &&
                            !with_linked_bundle &&
                            booking_units?.length > 1
                        ) {
                            return {
                                name: 'projectBookingPage',
                                params: { slug },
                            }
                        }
                    },
                },
            },
            {
                path: '/:slug/payment',
                name: 'projectPaymentPage',
                component: projectPaymentPage,
                props: true,
                meta: { checkOrderPossibility: true },
            },
            {
                path: '/:slug/self-checkin',
                name: 'projectSelfCheckInPage',
                component: projectSelfCheckInPage,
                props: true,
            },
            {
                path: '/:slug/:page(terms|privacy|cancelation)',
                name: 'projectStaticPage',
                component: projectStaticPage,
                props: true,
            },
            {
                path: '/:slug/passes',
                name: 'projectPassesPage',
                component: projectPassesPage,
                props: true,
                meta: { authRequired: true },
            },
            {
                path: '/:slug/passes-need-confirm',
                name: 'projectPassesNeedConfirmPage',
                component: projectPassesNeedConfirmPage,
                props: true,
                meta: {
                    checkBeforeEachRedirect: () => {
                        const slug = store.getters['project/slug']
                        const booking_unit = store.getters['cart/booking_unit']

                        if (!booking_unit) {
                            if (slug) {
                                return {
                                    name: 'projectPassesPage',
                                    params: { slug },
                                }
                            }
                            return {
                                name: 'indexPage',
                                params: { slug },
                            }
                        }
                    },
                },
            },
            {
                path: '/:slug',
                name: 'projectIndexPage',
                component: projectIndexPage,
                meta: { checkOrderPossibility: true },
                props: true,
            },
            {
                path: '/:slug/checkin',
                redirect: to => ({
                    name: 'projectSelfCheckInPage',
                    params: { slug: to.params.slug },
                }),
            },
            {
                path: '/:slug/update-dc',
                name: 'projectUpdateDCPage',
                component: projectUpdateDCPage,
                props: true,
            },
            {
                path: '/:slug/profile',
                redirect: to => ({ name: 'projectPassesPage', params: { slug: to.params.slug } }),
            },
            {
                path: '/:type(stampcard|coupon|membership|product)/:slug/:view?',
                redirect: to => to.fullPath.replace(`/${to.params.type}`, ''),
            },
            {
                path: '/:slug/:view',
                redirect: to => {
                    return { name: 'projectIndexPage', params: { slug: to.params.slug } }
                },
            },
            {
                path: '/pass/:pass_short_uuid',
                name: 'passIndexPage',
                component: passIndexPage,
                props: true,
            },
            {
                path: '/pass/:pass_short_uuid/details',
                name: 'passDetailsPage',
                component: passDetailsPage,
                props: true,
            },
            {
                path: '/pass/:pass_short_uuid/checkin',
                name: 'debugSelfCheckInPage',
                component: projectSelfCheckInPage,
                props: true,
                meta: { devOnly: true },
            },
            {
                path: '/pass/:pass_short_uuid/availability',
                name: 'passAvailabilityPage',
                component: passAvailabilityPage,
                props: true,
            },
            {
                name: '404',
                path: '/:pathMatch(.*)*',
                component: error404Page,
                props: true,
                meta: {
                    title: '404',
                    metaTags: [{ name: 'description', content: '404' }],
                },
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    if (from.query.kc && !to.query.kc) next({ ...to, query: { kc: from.query.kc, ...to.query } })
    else next()
})

router.afterEach(async (to, from) => {
    // Need to check if the route need to require campaign when project use the short url
    const checkOrderPossibility = to.meta.checkOrderPossibility
    const witeLabelRedirect = to.meta.witeLabelRedirect
    const devOnly = to.meta.devOnly
    const authRequired = to.matched.some(route => route.meta.authRequired)
    const noAuthRequired = to.matched.some(route => route.meta.noAuthRequired)
    const slug = to.params.slug !== '404' ? to.params.slug : undefined
    const pass_short_uuid = to.params.pass_short_uuid

    const checkEmailConfirmation = () => {
        const user = store.getters['account/user']

        if (
            user?.id &&
            !user.email_verified_at &&
            to.name !== 'emailConfirmationPage' &&
            to.name !== 'projectEmailConfirmationPage'
        ) {
            if (slug) router.replace({ name: 'projectEmailConfirmationPage', params: { slug } })
            else router.replace({ name: 'emailConfirmationPage' })

            return false
        }

        return true
    }

    try {
        if (devOnly && import.meta.env.VITE_APP_ENV === 'production') {
            return store.dispatch('showFull404Error')
        }

        if (authRequired && !hasToken()) store.dispatch('goToSignIn', undefined, { root: true })

        if (noAuthRequired && hasToken()) {
            if (slug) return router.replace({ name: 'projectIndexPage', params: { slug } })
            else return router.replace({ name: 'accountInformationPage' })
        }

        if (to.params.slug === '404') store.dispatch('showFull404Error')

        if (hasToken() && !store.getters['account/authenticated']) {
            store.dispatch('showAccountLoader')
        }

        const session = to.query.session
        if (session) {
            await store.dispatch('account/jwtLogin', { session, slug })
            const url = new URL(window.location)
            url.searchParams.delete('session')
            window.history.replaceState({}, document.title, url)
        }

        if (slug || (hasToken() && !pass_short_uuid)) {
            const campaign = to.query.kc
            let projectStore = store.state.store

            if (
                isEmpty(projectStore) ||
                slug !== projectStore.project?.slug ||
                slug !== from.params.slug
            ) {
                store.dispatch('showFullLoader')
                await store.dispatch('loadStore', { slug, campaign })

                projectStore = store.state.store
                projectStore.campaign = campaign
                projectStore.company_id = window.location.hash
                    ? window.location.hash.substr(1)
                    : null
            }

            document.title = projectStore.brand_name || 'Kinchaku'

            if (!checkEmailConfirmation()) return

            if (
                checkOrderPossibility &&
                !store.getters['project/can_proceed'] &&
                to.name !== 'projectIndexPage'
            ) {
                return router.replace({ name: 'projectIndexPage', params: { slug } })
            }
        } else if (pass_short_uuid) {
            const passStore = store.getters['pass/pass']

            if (
                isEmpty(passStore) ||
                passStore.shortuuid !== pass_short_uuid ||
                passStore.shortuuid !== from.params.pass_short_uuid
            ) {
                store.dispatch('showFullLoader')
                await store.dispatch('pass/loadPass', pass_short_uuid)
            }

            if (!checkEmailConfirmation()) return
        }

        if (
            hasToken() &&
            (!store.getters['account/authenticated'] ||
                (!from.path.startsWith('/account') && to.path.startsWith('/account')))
        ) {
            await store.dispatch('account/me', { global: !pass_short_uuid && !slug })

            if (!checkEmailConfirmation()) return
        }

        if (witeLabelRedirect && store.getters['is_whitelabel_mode']) {
            const witeLabelRedirectRoute = witeLabelRedirect()
            if (witeLabelRedirectRoute) return router.replace(witeLabelRedirectRoute)
        }
    } catch (e) {
        if (e == 401 || e?.status == 401 || e?.response?.status == 401) {
            store.dispatch('account/clearSession')
            store.dispatch('setStore', {})

            if (authRequired) store.dispatch('goToSignIn', undefined, { root: true })
        } else if (e == 404 || e?.status == 404 || e?.response?.status == 404) {
            return store.dispatch('showFull404Error')
        } else store.dispatch('handleError', e)
    } finally {
        const beforeEachRedirect =
            to.meta?.checkBeforeEachRedirect && to.meta.checkBeforeEachRedirect(from, to)

        if (beforeEachRedirect) {
            router.replace(beforeEachRedirect)
        } else {
            store.commit('SET_PRELOADED', true, { root: true })
            store.dispatch('hideFullLoader')
            store.dispatch('hideAccountLoader')
        }
    }
})

router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        window.location = to.fullPath
    }
})

export default router
